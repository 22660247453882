import React, { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton, Icon, Card, Box, styled, ThemeProvider, FormControl, Select, MenuItem } from "@mui/material";
import { createTheme, useTheme } from "@mui/material/styles";
import ColorBlock from "app/views/OtherMaster/ColorBlock";

const CardHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "16px 24px",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: theme.palette.success.main,
}));

const Title = styled("span")(({ theme }) => ({
  fontSize: "1.2rem",
  fontWeight: "bold",
  color: theme.palette.primary.contrastText,
}));

const ResponsiveTableContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
}));

const EnhancedTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  "& .MuiTableCell-head": {
    color: theme.palette.success.contrastText,
    fontWeight: "bold",
    fontSize: "1rem",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  '&.MuiTableCell-head': {
    borderBottom: `1px solid ${theme.palette.success.main}`,
  },
  '&:last-child': {
    textAlign: 'right',
    paddingRight: '15px',
  },
}));

// Include the provided CSS here
const StyledTableRow = styled(TableRow)({
  '.MuiGrid-item': {
    paddingTop: '0px',
  },
});

const Small = styled("small")(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: "#fff",
  padding: "2px 8px",
  borderRadius: "4px",
  overflow: "hidden",
  background: bgcolor,
  boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
}));

const OctillionTable = ({ title, columns, data, actions, pHandlePermissionChange }) => {
  const theme = useTheme();
  
  const handlePermissionChange = (event, row) => {
    pHandlePermissionChange(event,row);
  };

  const renderCellContent = (row, column, theme) => {
    if (column.field === "permission" && row.permission != '-') {
      return (
        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
          <Select
            value={row.permission}
            size="small"
            onChange={(event) => handlePermissionChange(event, row)}
          >
            <MenuItem value="Allowed">Allowed</MenuItem>
            <MenuItem value="Not Allowed">Not Allowed</MenuItem>
          </Select>
        </FormControl>
      );
    } else {
      switch (column.type) {
        case "status":
          return (
            <Small
              status={row[column.field]}
              bgcolor={
                row[column.field] === "A"
                  ? theme.palette.success.main
                  : row[column.field] === "P"
                  ? theme.palette.secondary.main
                  : theme.palette.error.main
              }
            >
              {row[column.field] === "A"
                ? "Active"
                : row[column.field] === "P"
                ? "Pending"
                : "Inactive"}
            </Small>
          );
        case "colourValue":
          return (
            <div style={{ width: '100px', height: '20px', border: '1px solid black', backgroundColor: row[column.field] }} />
          );
        case "backColorValue":
          return (
            <div style={{ width: '100px', height: '20px', border: '1px solid black', backgroundColor: row[column.field] }} />
          );
        case "fontColorValue":
          return (
            <div style={{ width: '100px', height: '20px', border: '1px solid black', backgroundColor: row[column.field] }} />
          );
        default:
          return row[column.field];
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Card elevation={3}>
        <CardHeader>
          <Title>{title}</Title>
        </CardHeader>

        <ResponsiveTableContainer>
          <Table>
            <EnhancedTableHead >
              <TableRow size="small">
                {columns.map((column) => (
                  <StyledTableCell key={column.field}>
                    {column.headerName}
                  </StyledTableCell>
                ))}
                {actions && <StyledTableCell>Actions</StyledTableCell>}
              </TableRow>
            </EnhancedTableHead>
            <TableBody>
              {data.map((row, index) => (
                <StyledTableRow key={index}>
                  {columns.map((column) => (
                    <StyledTableCell key={column.field}>
                      {renderCellContent(row, column, theme)}
                    </StyledTableCell>
                  ))}
                  {actions && (
                    <StyledTableCell>
                      {actions.map((action, actionIndex) => (
                        <IconButton
                          key={actionIndex}
                          onClick={() => action.onClick(row)}
                        >
                          <Icon color={action.color}>{action.icon}</Icon>
                        </IconButton>
                      ))}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </ResponsiveTableContainer>
      </Card>
    </ThemeProvider>
  );
};

export default OctillionTable;
