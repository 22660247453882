import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import Role from "./views/roles/Role";
import RoleRight from "./views/roles/RoleRight";
import ObdAnalytics from "./views/obdDashboard/ObdAnalytics";

// session pages
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const Forbidden = Loadable(lazy(() => import("app/views/sessions/Forbidden")));
const JwtRegister = Loadable(
  lazy(() => import("app/views/sessions/JwtRegister"))
);
const ForgotPassword = Loadable(
  lazy(() => import("app/views/sessions/ForgotPassword"))
);

// dashboard page
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
const Ticket = Loadable(
  lazy(() => import("app/views/tickets/ticketdetails/TicketDetail"))
);
const TicketForm = Loadable(lazy(() => import("app/views/tickets/TicketForm")));
//const TestTicket = Loadable(lazy(() => import("app/views/tickets/ticketdetails/TestTicketDetail")));
const UserProfile = Loadable(lazy(() => import("app/views/users/UserProfile")));

const UsersDetail = Loadable(
  lazy(() => import("app/views/admins/UsersDetail"))
);
const DealerDetail = Loadable(
  lazy(() => import("app/views/admins/DealerDetail"))
);
const CompanyDetail = Loadable(
  lazy(() => import("app/views/admins/CompanyDetail"))
);

const UserManagement = Loadable(
  lazy(() => import("app/views/admins/UserPermissionManagement"))
);
const ModuleAction = Loadable(
  lazy(() => import("app/views/modules/ModuleAction"))
);

const Projects = Loadable(
  lazy(() => import("app/views/OtherMaster/ProjectTable"))
);

const TicketStatus = Loadable(
  lazy(() => import("app/views/OtherMaster/TicketStatusTable"))
);

const Country = Loadable(
  lazy(() => import("app/views/OtherMaster/CountryTable"))
);

const State = Loadable(
  lazy(() => import("app/views/OtherMaster/StateTable"))
);

const City = Loadable(
  lazy(() => import("app/views/OtherMaster/CityTable"))
);
const EmailTemplateMaster = Loadable(
  lazy(() => import("app/views/OtherMaster/EmailTemplateMaster"))
);

const Oth_Master = Loadable(
  lazy(() => import("app/views/OtherMaster/OtherMasterList"))
);

const TimeLine = Loadable(
  lazy(() => import("app/views/timeline/TimeLine"))
);

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/session/403",
        element: <Forbidden />,
      },
      {
        path: "/timeline",
        element: <TimeLine />,
      },
      {
        path: "/dashboard/default",
        element: <Analytics />,
        auth: authRoles.admin,
      },
      {
        path: "/obddashboard",
        element: <ObdAnalytics />,
        auth: authRoles.admin,
      },
      {
        path: "/tickets/viewticket",
        element: <Ticket />,
        auth: authRoles.company,
      },
      {
        path: "/admin/users-detail",
        element: <UsersDetail />,
        auth: authRoles.admin,
      },
      {
        path: "/admin/dealer-detail",
        element: <DealerDetail />,
        auth: authRoles.admin,
      },
      {
        path: "/admin/company-detail",
        element: <CompanyDetail />,
        auth: authRoles.admin,
      },
      {
        path: "/admin/user-management",
        element: <UserManagement />,
        auth: authRoles.admin,
      },
      {
        path: "/tickets/create-ticket",
        element: <TicketForm />,
        auth: authRoles.company,
      },
      {
        path: "/users/user-profile",
        element: <UserProfile />,
      },
      {
        path: "/admin/role",
        element: <Role />,
      },
      {
        path: "/admin/role-right",
        element: <RoleRight />,
      },
      {
        path: "/developer/module-action",
        element: <ModuleAction />,
      },
      {
        path: "/Project",
        element: <Projects />,
      },
      {
        path: "/TicketStatus",
        element: <TicketStatus />,
      },
      {
        path: "/Country",
        element: <Country />,
      },
      {
        path: "/State",
        element: <State />,
      },
      {
        path: "/Cities",
        element: <City />,
      },
      {
        path: "/OtherMaster/DT",
        element: <Oth_Master record_id="DT" />,
      },
      {
        path: "/OtherMaster/DTC",
        element: <Oth_Master record_id="DTC" />,
      },
      {
        path: "/OtherMaster/CSTP",
        element: <Oth_Master record_id="CSTP" />,
      },
      {
        path: "/OtherMaster/RT",
        element: <Oth_Master record_id="RT" />,
      },
      {
        path: "/OtherMaster/CA",
        element: <Oth_Master record_id="CA" />,
      },
      {
        path: "/OtherMaster/RECT",
        element: <Oth_Master record_id="RECT" />,
      },
      {
        path: "/OtherMaster/CP",
        element: <Oth_Master record_id="CP" />,
      },
      {
        path: "/OtherMaster/OA",
        element: <Oth_Master record_id="OA" />,
      },
      {
        path: "/EmailTemplate",
        element: <EmailTemplateMaster  />,
      }
      
    ],
  },

  // session pages route
  //{ path: "", element: <Forbidden /> },
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },

  { path: "/", element: <Navigate to="session/signin" /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
