import { Card, Grid, Icon, Fab, styled, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import authApi from "api/authapi";
import useAuth from "app/hooks/useAuth";
import FilterComponent from "app/views/shared/FilterComponent";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Top10ComplaintWiseCard from "./Top10ComplaintWiseCard";
import Top20DealerTicketCard from "./Top20DealerTicketCard";
import ProjectWiseCard from "./ProjectWiseCard";
import DurationWiseCard from "./DurationWiseCard";
import StateWiseCard from "./StateWiseCard";
import CityWiseCard from "./CityWiseCard";
import { getUpdatedFilter } from "app/utils/utils";


const ContentBox = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
}));

const FabIcon = styled(Fab)(() => ({
  width: "40px !important",
  height: "40px !important",
  boxShadow: "none !important",
}));

const H3 = styled("h3")(({ textcolor }) => ({
  margin: 0,
  color: textcolor,
  fontWeight: "500",
  marginLeft: "1px", 
}));

const H1 = styled("h1")(({ textcolor }) => ({
  margin: 10,
  flexGrow: 1,
  color: textcolor
}));


const Span = styled("span")(({ textcolor }) => ({
  fontSize: "13px",
  color: textcolor,
  marginLeft: "4px",
}));

const IconBox = styled("div")(() => ({
  width: 16,
  height: 16,
  color: "#fff",
  display: "flex",
  overflow: "hidden",
  borderRadius: "300px ",
  justifyContent: "center",
  "& .icon": { fontSize: "14px" },
}));

const StatCards = () => {
  const [ProjectWiseData, setProjectWiseData] = useState([]);
  const [DurationWiseData, setDurationWiseData] = useState([]);
  const [Top20DealerTicketData, setTop20DealerTicketData] = useState([]);
  const [Top10ComplaintWiseData, setTop10ComplaintWiseData] = useState([]);
  const [StateWiseData, setStateWiseData] = useState([]);
  const [CityWiseData, setCityWiseData] = useState([]);
  const [CumulativeKMS, setCumulativeKMS] = useState(null);
  const [CvCount, setCvCount] = useState(null);
  const [PvCount, setPvCount] = useState(null);
  const [RepairedCount, setRepairedCount] = useState(null);
  const [ReplacedCount, setReplacedCount] = useState(null);
  
  const [TotalOpenTickets, setTotalOpenTickets] = useState(null);
  const [TotalClosedTickets, setTotalClosedTickets] = useState(null);
  const [TotalClosedTicketsInPlant, setTotalClosedTicketsInPlant] = useState(null);
  const [TotalClosedTicketsOnSite, setTotalClosedTicketsOnSite] = useState(null);
  const [FilterQueryString, setFilterQueryString]= useState("");
  const { user } = useAuth();
  const navigate = useNavigate();

  const fetchStatusDetails = async () => {
    console.log("Role is",user.role);
    const updatedFilters = getUpdatedFilter(filters);

    const params ={
      params: {
        PostedBy: user.role,
        PostedById: user.id,
        ...updatedFilters
      },
    }
    
      setCumulativeKMS(null);
      setCvCount(null);
      setPvCount(null);
      setRepairedCount(null);
      setReplacedCount(null);
      setTotalClosedTicketsOnSite(null);
      setTotalClosedTicketsInPlant(null);
      setTotalOpenTickets(null);
      setTotalClosedTickets(null);
    
    const filterQuery = new URLSearchParams(updatedFilters).toString();
    setFilterQueryString(filterQuery);
    authApi.get("/Tickets/GetTicketStatCardDetails",params ).then(response => {
      setCumulativeKMS(response.data.cumulativeKMS);
      setCvCount(response.data.countCV);
      setPvCount(response.data.countPV);
      setRepairedCount(response.data.countRepaired);
      setReplacedCount(response.data.countReplaced);
      setTotalClosedTicketsOnSite(response.data.totalClosedOnSite);
      setTotalClosedTicketsInPlant(response.data.totalClosedInPlant);

      let totalOpen=0;
      let totalClosed=0;  
      response.data.ticketStatCardResult.ticketStatCardDetails.forEach(detail => {
        if(detail.statusName == "Closed")
          totalClosed= detail.statusCount;
        else
        totalOpen += detail.statusCount;
      });
      setTotalOpenTickets(totalOpen);
      setTotalClosedTickets(totalClosed);
    });
    

    params.params.CardName="ProjectWise";
    authApi.get("/Tickets/GetDashboardCards",params ).then(responseProjectCard => {
      setProjectWiseData(responseProjectCard.data.data);
    });
    

      params.params.CardName="DurationWise";
      authApi.get("/Tickets/GetDashboardCards",params ).then(responseDurationCard => {
        setDurationWiseData(responseDurationCard.data.data);
      });

      params.params.CardName="Top20DealerWise";
      authApi.get("/Tickets/GetDashboardCards",params ).then(responseTop20DealerCard => {
        setTop20DealerTicketData(responseTop20DealerCard.data.data);
      });
      
      
      params.params.CardName="Top10ComplaintWise";
      authApi.get("/Tickets/GetDashboardCards",params ).then(responseTop10ComplaintWiseData => {
        setTop10ComplaintWiseData(responseTop10ComplaintWiseData.data.data);
      });
      
      params.params.CardName="StateWise";
      authApi.get("/Tickets/GetDashboardCards",params ).then(responseStateWiseData => {
        setStateWiseData(responseStateWiseData.data.data.sort((a, b) => a.name.localeCompare(b.name)));
      });

      params.params.CardName="CityWise";
      authApi.get("/Tickets/GetDashboardCards",params ).then(responseCityWiseData => {

        setCityWiseData(responseCityWiseData.data.data.sort((a, b) => a.name.localeCompare(b.name)));
      });
  };
  
  useEffect(() => {
    fetchStatusDetails();

        // Set up interval to fetch data every 5 minutes (300000 milliseconds)
        const intervalId = setInterval(()=>{
          handleResetFilters()
          fetchStatusDetails()
        }, 600000);
    
        // Clear interval on component unmount
        return () => clearInterval(intervalId);
  }, []);

  const [filters, setFilters] = useState({
    fromDate: "",
    toDate: "",
    status: [1,2,3,4,5,6,7,8,9,10,11,12],
    region: "",
    description: "",
    address:"",
    cityId:0,
    stateCode:"",
    projectType:[],
    project:[],
    dtcCodes:[],
    causalParts:[],
    ccCategory:[],
    selectedUserType:[],
    selectedUser:[],
  });
  const handleFilterChange = (field, value) => {
    setFilters({
      ...filters,
      [field]: value,
    });
  };

  const handleFilterChangeBulk = (newValues) => {
    setFilters({
      ...filters,
      ...newValues
    });
  };

  const handleApplyFilters = () => {
    fetchStatusDetails();
  };

  const handleResetFilters = () => {
    setFilters({
      fromDate: "",
      toDate: "",
      status: [1,2,3,4,5,6,7,8,9,10,11,12],
      region: "",
      description: "",
      address:"",
      cityId:0,
      stateCode:"",
      projectType:[],
      project:[],
      dtcCodes:[],
      causalParts:[],
      ccCategory:[],
      selectedUserType:[],
      selectedUser:[],
      selectedSingleUser:[],
    });
  };

  const TableCard = styled(Card)(({ theme }) => ({
    maxWidth: 450,
    maxHeight:265,
    overflow: "auto",
    padding:5,
    marginRight:20,
    marginTop:20,
  }));

  const redirectToTickets = (cardName) => {
    let queryString = FilterQueryString; // Assuming FilterQueryString is defined elsewhere
  
    switch (cardName) {
      case 'TotalTickets':
        queryString += ``;
        break;
      case 'TotalOpenTickets':
        queryString += `&status=1,2,3,4,5,6,7,8,10,11,12`;
        break;
      case 'TotalClosedOnSite':
        queryString += `&status=9`;
        break;
      case 'TotalClosedTicketsInPlant':
          queryString += `&status=9`;
          break;
      case 'CV':
          queryString += `&projectType=CV`;
          break;
      case 'PV':
          queryString += `&projectType=PV`;
          break;
          case 'Repaired':
          queryString += `&selectedRepaired=Yes`;
          break;
          case 'Replaced':
          queryString += `&selectedReplaced=Yes`;
          break;
      default:
        queryString += ``;
        break;
    }
  
    navigate(`/tickets/viewticket?${queryString}`);
  };

  return (
    
    <Fragment>
      <FilterComponent
            filters={filters}
            onFilterChange={handleFilterChange}
            onFilterChangeBulk ={handleFilterChangeBulk}
            onApplyFilters={handleApplyFilters}
            onResetFilters={handleResetFilters}
            user  ={user} 
          />
          
        <Grid container sx={{ mb: 3, width: 'auto', justifyContent: 'flex-start' }}>
        <Grid key={22} sx={{ padding: 1, display: 'flex', justifyContent: 'center', minWidth: '140px', maxWidth: '100%' }} >
              <Card  onClick={()=> redirectToTickets("TotalTickets")} elevation={3} sx={{ p: 1, backgroundColor:'#0074a2', display: 'flex', flexDirection: 'column', minHeight: '100%', width: '100%'}} className={TotalOpenTickets ==null || TotalClosedTickets ==null ? 'shimmer' : ''}>
                <ContentBox sx={{ flexGrow: 1, flexWrap: 'wrap' }}>
                  <FabIcon size="medium" sx={{ background: "rgba(9, 182, 109, 0.15)", mr: 1 }}>
                    <Icon sx={{ color: '#000000' }}>assignment_turned_in</Icon>
                  </FabIcon>
                  <H1 textcolor='#000000'>{TotalOpenTickets + TotalClosedTickets}</H1>                
                </ContentBox>
                <ContentBox>
                  <H3 textcolor='#000000'>Total Tickets</H3>
                </ContentBox>
              </Card>
            </Grid>
            <Grid key={23} sx={{ padding: 1, display: 'flex', justifyContent: 'center', minWidth: '140px', maxWidth: '100%' }} >
              <Card onClick={()=> redirectToTickets("TotalOpenTickets")} elevation={3} sx={{ p: 1, backgroundColor:'#fed350', display: 'flex', flexDirection: 'column', minHeight: '100%', width: '100%' }} className={TotalOpenTickets ==null ? 'shimmer' : ''}>
                <ContentBox sx={{ flexGrow: 1, flexWrap: 'wrap' }}>
                  <FabIcon size="medium" sx={{ background: "rgba(9, 182, 109, 0.15)", mr: 1 }}>
                    <Icon sx={{ color: '#000000' }}>engineering</Icon>
                  </FabIcon>
                  <H1 textcolor='#000000'>{TotalOpenTickets}</H1>                
                </ContentBox>
                <ContentBox>
                  <H3 textcolor='#000000'>Total Open Tickets</H3>
                </ContentBox>
              </Card>
            </Grid>
              <Grid key={24} sx={{ padding: 1, display: 'flex', justifyContent: 'center', minWidth: '140px', maxWidth: '100%' }} >
                <Card onClick={()=> redirectToTickets("TotalClosedOnSite")} elevation={3} sx={{ p: 1, backgroundColor:'#138a2c', display: 'flex', flexDirection: 'column', minHeight: '100%', width: '100%' }} className={TotalClosedTicketsOnSite ==null ? 'shimmer' : ''}>
                  <ContentBox sx={{ flexGrow: 1, flexWrap: 'wrap' }}>
                    <FabIcon size="medium" sx={{ background: "rgba(9, 182, 109, 0.15)", mr: 1 }}>
                      <Icon sx={{ color: '#FFFFFF' }}>tab_close</Icon>
                    </FabIcon>
                    <H1 textcolor='#FFFFFF'>{TotalClosedTicketsOnSite} </H1>                 
                  </ContentBox>
                  <ContentBox>
                    <H3 textcolor='#FFFFFF'>Total Closed (On Site)</H3>
                  </ContentBox>
                </Card>
              </Grid>
              <Grid key={241} sx={{ padding: 1, display: 'flex', justifyContent: 'center', minWidth: '140px', maxWidth: '100%' }} >
                <Card elevation={3} sx={{ p: 1, backgroundColor:'#138a2c', display: 'flex', flexDirection: 'column', minHeight: '100%', width: '100%' }} className={TotalClosedTicketsInPlant ==null ? 'shimmer' : ''}>
                  <ContentBox sx={{ flexGrow: 1, flexWrap: 'wrap' }}>
                    <FabIcon size="medium" sx={{ background: "rgba(9, 182, 109, 0.15)", mr: 1 }}>
                      <Icon sx={{ color: '#FFFFFF' }}>tab_close</Icon>
                    </FabIcon>
                    <H1 textcolor='#FFFFFF'>{TotalClosedTicketsInPlant} </H1>                 
                  </ContentBox>
                  <ContentBox>
                    <H3 textcolor='#FFFFFF'>Total Closed (In Plant)</H3>
                  </ContentBox>
                </Card>
              </Grid>
              <Grid key={25} sx={{ padding: 1, display: 'flex', justifyContent: 'center', minWidth: '140px', maxWidth: '100%' }} >
                <Card onClick={()=> redirectToTickets("TotalClosedOnSite")} elevation={3} sx={{ p: 1, backgroundColor:'#1e73bf', display: 'flex', flexDirection: 'column', minHeight: '100%', width: '100%' }} className={TotalClosedTicketsOnSite ==null ? 'shimmer' : ''}>
                  <ContentBox sx={{ flexGrow: 1, flexWrap: 'wrap' }}>
                    <FabIcon size="medium" sx={{ background: "rgba(9, 182, 109, 0.15)", mr: 1 }}>
                      <Icon sx={{ color: '#FFFFFF' }}>restart_alt</Icon>
                    </FabIcon>
                    <H1 textcolor='#FFFFFF'>{TotalClosedTicketsOnSite} </H1>                 
                  </ContentBox>
                  <ContentBox>
                    <H3 textcolor='#FFFFFF'>Vehicle On Road</H3>
                  </ContentBox>
                </Card>
              </Grid>
              <Grid key={26} sx={{ padding: 1, display: 'flex', justifyContent: 'center', minWidth: '140px', maxWidth: '100%' }} >
                <Card onClick={()=> redirectToTickets("TotalTickets")} elevation={3} sx={{ p: 1, backgroundColor:'#81d742', display: 'flex', flexDirection: 'column', minHeight: '100%', width: '100%' }} className={CumulativeKMS ==null ? 'shimmer' : ''}>
                  <ContentBox sx={{ flexGrow: 1, flexWrap: 'wrap' }}>
                    <FabIcon size="medium" sx={{ background: "rgba(9, 182, 109, 0.15)", mr: 1 }}>
                      <Icon sx={{ color: '#FFFFFF' }}>restart_alt</Icon>
                    </FabIcon>
                    <H1 textcolor='#FFFFFF'>{CumulativeKMS} </H1>                 
                  </ContentBox>
                  <ContentBox>
                    <H3 textcolor='#FFFFFF'>Cumulative KMS</H3>
                  </ContentBox>
                </Card>
              </Grid>
              <Grid key={27} sx={{ padding: 1, display: 'flex', justifyContent: 'center', minWidth: '140px', maxWidth: '100%' }} >
                <Card onClick={()=> redirectToTickets("CV")} elevation={3} sx={{ p: 1, backgroundColor:'#0074a2', display: 'flex', flexDirection: 'column', minHeight: '100%', width: '100%' }} className={CvCount ==null ? 'shimmer' : ''}>
                  <ContentBox sx={{ flexGrow: 1, flexWrap: 'wrap' }}>
                    <FabIcon size="medium" sx={{ background: "rgba(9, 182, 109, 0.15)", mr: 1 }}>
                      <Icon sx={{ color: '#FFFFFF' }}>restart_alt</Icon>
                    </FabIcon>
                    <H1 textcolor='#FFFFFF'>{CvCount} </H1>                 
                  </ContentBox>
                  <ContentBox>
                    <H3 textcolor='#FFFFFF'>CV</H3>
                  </ContentBox>
                </Card>
              </Grid>
              <Grid key={28} sx={{ padding: 1, display: 'flex', justifyContent: 'center', minWidth: '140px', maxWidth: '100%' }} >
                <Card onClick={()=> redirectToTickets("PV")} elevation={3} sx={{ p: 1, backgroundColor:'#1e73be', display: 'flex', flexDirection: 'column', minHeight: '100%', width: '100%' }} className={PvCount ==null ? 'shimmer' : ''}>
                  <ContentBox sx={{ flexGrow: 1, flexWrap: 'wrap' }}>
                    <FabIcon size="medium" sx={{ background: "rgba(9, 182, 109, 0.15)", mr: 1 }}>
                      <Icon sx={{ color: '#FFFFFF' }}>restart_alt</Icon>
                    </FabIcon>
                    <H1 textcolor='#FFFFFF'>{PvCount} </H1>                 
                  </ContentBox>
                  <ContentBox>
                    <H3 textcolor='#FFFFFF'>PV</H3>
                  </ContentBox>
                </Card>
              </Grid>
              <Grid key={29} sx={{ padding: 1, display: 'flex', justifyContent: 'center', minWidth: '140px', maxWidth: '100%' }} >
                <Card onClick={()=> redirectToTickets("Repaired")} elevation={3} sx={{ p: 1, backgroundColor:'#1FF3be', display: 'flex', flexDirection: 'column', minHeight: '100%', width: '100%' }} className={RepairedCount ==null ? 'shimmer' : ''}>
                  <ContentBox sx={{ flexGrow: 1, flexWrap: 'wrap' }}>
                    <FabIcon size="medium" sx={{ background: "rgba(9, 182, 109, 0.15)", mr: 1 }}>
                      <Icon sx={{ color: '#FFFFFF' }}>restart_alt</Icon>
                    </FabIcon>
                    <H1 textcolor='#FFFFFF'>{RepairedCount} </H1>                 
                  </ContentBox>
                  <ContentBox>
                    <H3 textcolor='#FFFFFF'>Repaired</H3>
                  </ContentBox>
                </Card>
              </Grid>
              <Grid key={30} sx={{ padding: 1, display: 'flex', justifyContent: 'center', minWidth: '140px', maxWidth: '100%' }} >
                <Card onClick={()=> redirectToTickets("Replaced")} elevation={3} sx={{ p: 1, backgroundColor:'#1e73FF', display: 'flex', flexDirection: 'column', minHeight: '100%', width: '100%' }} className={ReplacedCount ==null ? 'shimmer' : ''}>
                  <ContentBox sx={{ flexGrow: 1, flexWrap: 'wrap' }}>
                    <FabIcon size="medium" sx={{ background: "rgba(9, 182, 109, 0.15)", mr: 1 }}>
                      <Icon sx={{ color: '#FFFFFF' }}>restart_alt</Icon>
                    </FabIcon>
                    <H1 textcolor='#FFFFFF'>{ReplacedCount} </H1>                 
                  </ContentBox>
                  <ContentBox>
                    <H3 textcolor='#FFFFFF'>Replaced</H3>
                  </ContentBox>
                </Card>
              </Grid>
        </Grid>
        <Grid container sx={{ mb: 3, width: 'auto', justifyContent: 'flex-start' }}>
          <TableCard><ProjectWiseCard data={ProjectWiseData} filterQueryString={FilterQueryString}/></TableCard>
          <TableCard><DurationWiseCard data={DurationWiseData} filterQueryString={FilterQueryString}/></TableCard>
          <TableCard><Top20DealerTicketCard  data={Top20DealerTicketData} filterQueryString={FilterQueryString}/></TableCard>
          <TableCard><Top10ComplaintWiseCard data={Top10ComplaintWiseData}  filterQueryString={FilterQueryString}/></TableCard>
          <TableCard><StateWiseCard data={StateWiseData}  filterQueryString={FilterQueryString}/></TableCard>
          <TableCard><CityWiseCard data={CityWiseData}  filterQueryString={FilterQueryString}/></TableCard>
        </Grid>
  </Fragment>
  );
};

export default StatCards;
