import React from "react";
import OctillionTable from "app/components/OctillionTableDashboard";

const CityWiseCard = (cardData) => {
  const columns = [
    { field: "name", headerName: "City" },
    { field: "total", headerName: "Total" },
  ];

  return (
    <OctillionTable
            columns={columns}
            data={cardData.data}
            headercolorname="#138a2c"
            maxHeight={250}
            calledBy="CityWiseCard"
            filterQueryString={cardData.filterQueryString}
          />
  );
};

export default CityWiseCard;
