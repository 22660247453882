import axios from "axios";

const authApi = axios.create({
  //baseURL: 'https://localhost:7249/api/',
  //baseURL: "https://stage-ev-services.azurewebsites.net/api/", //Staging-Single App Service
  baseURL: "https://octillion-ev-services.azurewebsites.net/api/", //Production
  headers: {
    Accept: "applicaiton/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
     mode: "no-cors",
  }, // Replace with your actual API base URL
});

export default authApi;
