import React, { useState } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

const FilterComponent = ({ statuses, onFilter }) => {
  const [selectedStatus, setSelectedStatus] = useState('');

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    onFilter(event.target.value);
  };

  const applyFilter = () => {
    onFilter(selectedStatus);
  };

  return (
    <Box display="flex" gap={2} alignItems="center">
      <FormControl variant="outlined" size="small" sx={{ width: 200 }}>
        <InputLabel>Status</InputLabel>
        <Select
          value={selectedStatus}
          onChange={handleStatusChange}
          label="Status"
        >
          <MenuItem value=""><em>All</em></MenuItem>
          {statuses.map((status, index) => (
            <MenuItem key={index} value={status.value}>
              {status.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* <Button variant="contained" color="success" onClick={applyFilter}>
        Apply Filter
      </Button> */}
    </Box>
  );
};

export default FilterComponent;
