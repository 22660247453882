import React, { useEffect, useState } from "react";
import authApi from "api/authapi"; // Ensure this path is correct for your project structure
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import OctillionTable from "app/components/OctillionTable";
import FilterComponent from "app/components/FilterComponent";
import AddEditRoleDialog from "./AddEditRoleDialog";

const ModuleActionTable = ({ usertype, isNewUserAdded }) => {
  const [users, setUsers] = useState([]); // Original dataset
  const [filteredUsers, setFilteredUsers] = useState([]); // Filtered dataset for display
  const [selectedModule, setSelectedModule] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [filterStatus, setFilterStatus] = useState("");

  useEffect(() => {
    fetchData(filterStatus);
  }, [isNewUserAdded, filterStatus]); // Ensure fetchData is called when filterStatus changes

  const fetchData = async () => {
    try {
      const response = await authApi.get("/user/GetRoles");
      const roleData = response.data.roles
      .map((role) => ({
        ...role,
        oldRoleName:role.roleName
      }));
        
      console.log("roleData", roleData);
      setUsers(roleData);
      setFilteredUsers(roleData); // Initially, no filter is applied
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEditClick = (role) => {
    setSelectedModule(role);
    setDialogOpen(true);
  };

  const handleConfirm = async (formattedValues) => {
    console.log("formattttt.", JSON.stringify(formattedValues));
    await authApi.post("ModuleRights/AddEditRole",formattedValues);

    setDialogOpen(false);
    fetchData(filterStatus);
  };

  const handleCancel = () => {
    setDialogOpen(false);
    setConfirmationAction(null);
    setSelectedModule(null);
  };

  const handleFilter = (selectedStatus) => {
    if (selectedStatus) {
      const filtered = users.filter(
        (user) =>
          user.recordStatus.toLowerCase() === selectedStatus.toLowerCase()
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users); // Reset to all users if no status selected
    }
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const columns = [
    { field: "roleName", headerName: "Name" },
    // { field: "parentName", headerName: "Parent Name" },
    { field: "roleHierarchy", headerName: "Hierarchy" },
    // { field: "moduleActions", headerName: "Module Actions" }
  ];

  const actions = [
    {
      icon: "edit",
      color: "success",
      onClick: (module) => {
        handleEditClick(module);
      },
    },
    // {
    //   icon: "delete",
    //   color: "error",
    //   onClick: (comp) => {
    //     handleIconClick(comp, "delete");
    //   },
    // },
    // other actions...
  ];

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        {" "}
        <Button variant="contained" color="success" onClick={handleOpen} style={{ marginLeft: '10px' }}>
        Add Role
        </Button>
        
        <AddEditRoleDialog
          open={open}
          handleSubmit={handleConfirm}
          handleClose={handleClose}
          data={selectedModule}
          mode={'add'}
        />
      </Box>

      <OctillionTable
        title="Roles"
        columns={columns}
        data={filteredUsers}
        actions={actions}
      />
      
      <AddEditRoleDialog
        open={isDialogOpen}
        handleSubmit={handleConfirm}
        handleClose={handleCancel}
        data={selectedModule}
        mode={'edit'}
      />
    </Box>
  );
};

export default ModuleActionTable;
