import { createContext, useEffect, useReducer, useState } from "react";
import { MatxLoading } from "app/components";
import authApi from "api/authapi";
import { Snackbar, SnackbarContent } from "@mui/material";

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case "LOGIN": {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }

    case "REGISTER": {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  updateProfile:() => {},
  logout: () => {},
  register: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const login = async (email, password) => {
    try {
      const response = await authApi.post("/User/Login", { email, password });
      const { user, isSuccess } = response.data;
      console.log("success is response:", response.data);
      if (isSuccess) {
        localStorage.setItem("authUser", JSON.stringify(user));
        dispatch({ type: "LOGIN", payload: { user } });
        setSnackbarColor("green");
        setSnackbarMessage(response.data.message);
        setOpenSnackbar(true);
        return true; // Return true on successful login
      } else {
        setSnackbarColor("orange");
        setSnackbarMessage(response.data.message);
        setOpenSnackbar(true);
        return false; // Return false on unsuccessful login
      }
    } catch (error) {
      setSnackbarColor("red");
      setOpenSnackbar(false);
      return false; // Return false on error
    }
  };
  const updateProfile = async (updatedUserData) => {
    try {
      const storedUser = localStorage.getItem("authUser");
      if (storedUser !== "undefined") {
        var user = JSON.parse(storedUser);
        user = {...user,...updatedUserData};
        console.log("user",user);
        localStorage.setItem("authUser", JSON.stringify(user));
        dispatch({ type: "LOGIN", payload: { user } });
      }
    } catch (error) {
      return false; // Return false on error
    }
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const register = async (
    userName,
    email,
    phoneNumber,
    dealerCode,
    companyName,
    companyId
  ) => {
    console.log("signup started", email);
    const response = await authApi.post("/Dealer/DealerSignup", {
      userName: userName,
      email: email,
      phoneNumber: phoneNumber,
      dealerCode: dealerCode,
      // companyName: companyName,
      // companyId : companyId
    });
    const {user, status } = response.data;
      console.log("Register  response:", response);
      if (status == "Success") {
        localStorage.setItem("authUser", JSON.stringify(user));
        dispatch({ type: "REGISTER", payload: { user } });
        return true;
      } else {
        setSnackbarColor("orange");
        setSnackbarMessage(response.data.message);
        setOpenSnackbar(true);
        return false; // Return false on unsuccessful login
      }

  };

  const logout = () => {
    localStorage.removeItem("authUser");
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("authUser");
    if (storedUser !== "undefined") {
      const user = JSON.parse(storedUser);
      dispatch({ type: "INIT", payload: { isAuthenticated: true, user } });
    } else {
      dispatch({
        type: "INIT",
        payload: { isAuthenticated: false, user: null },
      });
    }
  }, []);

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    <>
      <AuthContext.Provider
        value={{ ...state, method: "JWT", login,updateProfile, logout, register }}
      >
        {children}
      </AuthContext.Provider>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <SnackbarContent
          style={{ backgroundColor: snackbarColor, color: "white" }}
          message={snackbarMessage}
        />
      </Snackbar>
    </>
  );
};

export default AuthContext;
