import React, { useEffect, useState } from "react";
import authApi from "api/authapi"; // Ensure this path is correct for your project structure
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import OctillionTable from "app/components/OctillionTableDashboard";

const Top20DealerTicketCard = (cardData) => {
  const columns = [
    { field: "name", headerName: "Top 20 Dealers" },
    { field: "total", headerName: "Total" },
  ];

  return (
    <OctillionTable
            columns={columns}
            data={cardData.data}
            headercolorname="blue"
            maxHeight={250}
          />
  );
};

export default Top20DealerTicketCard;
