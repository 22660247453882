// CitySearch.js
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import authApi from "api/authapi";
import { Box, styled } from "@mui/material";
import { useRef } from "react";

const CityListContainer = styled(Box)(() => ({
  position: "relative",
  marginBottom: "2px",
}));
const CityList = styled(Box)(() => ({
  position: "absolute",
  left: "0",
  width: "100%",
  maxHeight: "200px",
  overflowY: "auto",
  border: "2px solid #ccc",
  backgroundColor: "#ffffff",
  zIndex: "1000",
}));

const CityDiv = styled(Box)(() => ({
  fontSize: "bold",
  color: "black",
  cursor: "pointer",
  ":hover": {
    backgroundColor: "#bdbdbd",
  },
}));

const CitySearch = ({ onCityChange, textfieldSize, stateSelected,citySelected,hideError }) => {
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      setSearchText("");
      setFilteredCities([]); // Clear the filtered results
      setSelectedCity(""); // Update the selected city
      onCityChange(""); // Pass
    } else {
      isMounted.current = true;
    }
  }, [stateSelected]);

  const [searchText, setSearchText] = useState(citySelected);
  const [allCities, setAllCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(citySelected);

  // Simulating an API call to fetch all cities (calling it once)
  useEffect(() => {
    console.log("citySelected",citySelected);
    const fetchAllCities = async () => {
      try {
        // Replace the following line with your actual API endpoint
        const response = await authApi.get("/MasterData/GetCities");
        const cities = await response.data.cities;
        setAllCities(cities);
      } catch (error) {
        console.error("Error fetching all cities:", error);
      }
    };

    fetchAllCities();
   
  }, []); // Empty dependency array ensures the effect runs only once

  
  const handleInputChange = (event) => {
    
    const text = event.target.value;
    setSearchText(text);
    console.log("search is:", text)
    console.log("State selected", stateSelected);
  
    // Check if the text is not empty before filtering
    if (text.trim() !== '') {
      // Filter cities based on the search text
      const filteredResults = allCities.filter((city) => 
        city.stateCode.includes(stateSelected) &&
        city.cityName.toLowerCase().includes(text.toLowerCase())
      );
      setFilteredCities(filteredResults);
     
      console.log(filteredResults);
    } else {
      // If text is empty, include all cities
      setFilteredCities([]);
      
    }
  };

  const handleCitySelect = (selectedCity) => {
    setSearchText(selectedCity.cityName);
    setFilteredCities([]); // Clear the filtered results
    setSelectedCity(selectedCity); // Update the selected city
    onCityChange(selectedCity); // Pass
  };

  return (
    <div>
      <TextField
        label="City"
        size={textfieldSize}
        type="text"
        variant="outlined"
        value={searchText}
        onChange={handleInputChange}
        fullWidth
        disabled={stateSelected === ''} // Disable the TextField if stateSelected is blank
        sx={{ mb: 2 }}
        error={ searchText === '' && hideError == undefined} // Set error to true if stateSelected is not blank and searchText is empty
        helperText={ searchText === ''  && hideError == undefined ? "City is required" : ""}
        InputProps={{
          autoComplete: 'nope' // Disable browser autocomplete
        }}
      />
      <CityListContainer>
        {filteredCities.length > 0 && (
          <CityList>
            {filteredCities.map((city) => (
              <CityDiv key={city.id} onClick={() => handleCitySelect(city)}>
                {city.cityName}
              </CityDiv>
            ))}
          </CityList>
        )}
      </CityListContainer>
    </div>
  );
};

export default CitySearch;
