import { authRoles } from "./auth/authRoles";

export const navigations = [
  { label: "Dashboard", type: "label", auth: authRoles.dealer },
  { name: "Overall", path: "/dashboard/default", icon: "dashboard", securityName:"Overall Dashboard",securityAction:"Allow" },
  { name: "OBD Dashboard", path: "/obddashboard", icon: "dashboard", securityName:"OBD Dashboard",securityAction:"Allow"  },

  { label: "Ticket", type: "label", auth: authRoles.dealer },
  {
    name: "Tickets",
    icon: "local_activity",
    path: "/tickets/viewticket",
    auth: authRoles.dealer,
    securityName:"Tickets",
    securityAction:"View"
  },
  { label: "Admin", type: "label", auth: authRoles.admin },
  {
    name: "Dealers / Company",
    icon: "security",
    auth: authRoles.admin,
    children: [
      { name: "Dealers", iconText: "SU", path: "/admin/dealer-detail", securityName:"Dealers",securityAction:"Approve / Reject" },
      { name: "Company", iconText: "SU", path: "/admin/company-detail", securityName:"Companies",securityAction:"Add_Edit_Approve / Reject"  },
      // { name: "User Management", iconText: "SU", path: "/admin/user-management" },
    ],
  },
  {
    name: "Users / User Roles",
    icon: "security",
    auth: authRoles.admin,
    children: [
      { name: "Users", iconText: "SU", path: "/admin/users-detail", securityName:"Users",securityAction:"Add_Edit_Approve / Reject" },
      { name: "User Role Rights", iconText: "SU", path: "/admin/role-right", securityName:"User Role Rights",securityAction:"Edit"  },
    ],
  },
  {
    name: "Other Masters",
    icon: "security",
    auth: authRoles.admin,
    children: [
      { name: "Projects", iconText: "SU", path: "/Project", securityName:"Projects",securityAction:"Add_Edit_Delete" },
      { name: "Problem Statement (DT)", iconText: "SU", path: "/OtherMaster/DT", securityName:"Problem Statements",securityAction:"Add_Edit_Delete" },
      { name: "Diagnostic Trouble Code", iconText: "SU", path: "/OtherMaster/DTC", securityName:"DTC Codes",securityAction:"Add_Edit_Delete" },
      { name: "Ticket Status", iconText: "SU", path: "/TicketStatus", securityName:"Ticket Status",securityAction:"Add_Edit_Delete" },
      { name: "Customer Complain Category", iconText: "SU", path: "/OtherMaster/CSTP", securityName:"Complaint Category",securityAction:"Add_Edit_Delete" },
      { name: "Resolution Type", iconText: "SU", path: "/OtherMaster/RT", securityName:"Resolution Type",securityAction:"Add_Edit_Delete" },
      { name: "Causal Aggregate", iconText: "SU", path: "/OtherMaster/CA", securityName:"Causal Aggregate",securityAction:"Add_Edit_Delete" },
      //{ name: "Defect Type", iconText: "SU", path: "/OtherMaster/DT", securityName:"Defect Types",securityAction:"Add_Edit_Delete" },
      { name: "Rectification Type", iconText: "SU", path: "/OtherMaster/RECT", securityName:"Rectification Types",securityAction:"Add_Edit_Delete" },
      { name: "Causal Parts", iconText: "SU", path: "/OtherMaster/CP", securityName:"Causal Parts",securityAction:"Add_Edit_Delete" },
      { name: "Other Activity", iconText: "SU", path: "/OtherMaster/OA", securityName:"Other Activities",securityAction:"Add_Edit_Delete" },
      { name: "Country", iconText: "SU", path: "/Country", securityName:"Country",securityAction:"Add_Edit_Delete" },
      { name: "State", iconText: "SU", path: "/State", securityName:"States",securityAction:"Add_Edit_Delete" },
      { name: "City", iconText: "SU", path: "/Cities", securityName:"Cities",securityAction:"Add_Edit_Delete" },
      { name: "Email Template", iconText: "SU", path: "/EmailTemplate", securityName:"Email Template Master",securityAction:"Edit" },
    ],
  },
  {
    name: "Developer",
    icon: "security",
    auth: authRoles.admin,
    children: [
      { name: "Modules and Actions", iconText: "SU", path: "/developer/module-action" },
      { name: "User Role", iconText: "SU", path: "/admin/role" },
    ],
  },
];
