// permissions.js

import { useEffect } from "react";
import useAuth from "app/hooks/useAuth";

export const useUserPermission = (moduleName, actionName) => {
  const { user } = useAuth();
  // console.log("user",user);
  // console.log("moduleName",moduleName);
  return moduleName !=undefined && user!=null && user.userModuleDetails.modules.some(
      (module) =>
        module.moduleName.toLowerCase() === moduleName.toLowerCase() &&
        module.moduleActions.some(
          (action) =>
            action.actionName.toLowerCase() === actionName.toLowerCase() &&
            action.isAllowed
        )
    );
};

// Example of how to use this custom hook in a component:
/*
import { useUserPermission } from "./permissions";

const MyComponent = () => {
  const hasUserPermission = useUserPermission();

  useEffect(() => {
    // Example usage
    const hasPermission = hasUserPermission("moduleName", "actionName");
    console.log("Has permission:", hasPermission);
  }, []);

  return <div>My Component</div>;
};

export default MyComponent;
*/
