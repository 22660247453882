  import useAuth from 'app/hooks/useAuth';
  import { Navigate, useLocation } from 'react-router-dom';
  import { navigations } from 'app/navigations';
import { authRoles, hasUserPermission, pathRoles } from './authRoles';
import UserPermissionManagement from 'app/views/admins/UserPermissionManagement';
import { useUserPermission } from 'app/contexts/Permissions';

  const AuthGuard = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const { pathname } = useLocation();
    const { user } = useAuth();
    
    if (isAuthenticated) 
    {
      const paths = pathRoles[pathname]
    
      console.log("user",user);
      console.log("navigations",navigations);
      console.log("pathname",pathname);

      if(paths!=undefined && paths.length ==2 && !hasUserPermission(user, paths[0],paths[1])){
        
        console.log("pathname",hasUserPermission(user, paths[0],paths[1]));
        return <Navigate replace to="/session/403" state={{ from: pathname }} />;    
      }
      if(user.role!='Developer' && (pathname=='/developer/module-action' || pathname=='/admin/role' )){
       return <Navigate replace to="/session/403" state={{ from: pathname }} />;    
      }
      return <>{children}</>;

    }

    return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
  };

  export default AuthGuard;
