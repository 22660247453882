import axios from "axios";

const authApiBlob = (url, params = {}) => {
  //const apiUrl = 'https://localhost:7249/api' + url;
  //const apiUrl = 'https://stage-ev-services.azurewebsites.net/api' + url;
  const apiUrl = 'https://octillion-ev-services.azurewebsites.net/api' + url;
  return axios.get(apiUrl, {
    responseType: 'blob',
    ...params, // Include the query parameters here
    headers: {
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  });
};

export default authApiBlob;
