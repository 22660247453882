import React, { useEffect, useState } from "react";
import authApi from "api/authapi"; // Ensure this path is correct for your project structure
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import OctillionTable from "app/components/OctillionTableDashboard";

const ProjectWiseCard = (cardData) => {
  const columns = [
    { field: "name", headerName: "Projects" },
    { field: "total", headerName: "Total" },
  ];

  return (
    <OctillionTable
            columns={columns}
            data={cardData.data}
            headercolorname="#62ba52"
            calledBy="ProjectsCard"
            filterQueryString={cardData.filterQueryString}
          />
  );
};

export default ProjectWiseCard;
