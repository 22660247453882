import React, { useState, useEffect, useRef } from "react";
import {
    Grid,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    ListItemText,
    IconButton,
    CircularProgress,
    Tooltip,
    OutlinedInput,
    Autocomplete
} from "@mui/material";
import { Card, CardContent, styled } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import MinimizeIcon from '@mui/icons-material/Minimize';
import RegionPopup from "./RegionPopup";
import authApi from "api/authapi";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import authApiBlob from "api/authapiBlob";
import useAuth from "app/hooks/useAuth";
import GetAppIcon from '@mui/icons-material/GetApp'; 
import { hasUserPermission } from "app/auth/authRoles";
import { getUpdatedFilter } from "app/utils/utils";

const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    '& .MuiCardContent-root': {
        padding: '0px !important',
        paddingBottom: '10px !important',
    },
}));

const FilterLabel = styled("h5")({
    textAlign: "center",
    fontSize: "0.9rem",
});

const FormControlSelect = styled(FormControl)(() => ({
    width: "100%",
    marginBottom: "10px",
}));
const FilterComponent = ({
    filters,
    onFilterChange,
    onFilterChangeBulk,
    onApplyFilters,
    onResetFilters,
    pStatusOptions,
    user,
    isPopupOpened,
    currentPage,
    setTickets,
    setTotalPages,
    setLoading
}) => {
    const [regionDialogOpen, setRegionDialogOpen] = useState(false);
    const [isPopUpOpened, setIsPopUpOpened] = useState(false);
    const [statusOptions, setStatusOptions] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState(filters.status);
    const [DtcCodeOptions, setDtcCodeOptions] = useState([]);
    const [selectedDtcCodes, setSelectedDtcCodes] = useState(filters.dtcCodes || []);
    const [CausalPartOptions, setCausalPartOptions] = useState([]);
    const [selectedCausalParts, setSelectedCausalParts] = useState(filters.causalParts || []);
    const [CcCategoryOptions, setCcCategoryOptions] = useState([]);
    const [selectedCcCategories, setSelectedCcCategories] = useState(filters.ccCategory || []);
    const [UserTypeOptions, setUserTypeOptions] = useState([]);
    const [selectedUserType, setSelectedUserType] = useState(filters.selectedUserType || []);
    const [UserOptions, setUserOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(filters.selectedUser || []);
    const [selectedRepaired, setSelectedRepaired] = useState(filters.selectedRepaired || '');
    const [selectedReplaced, setSelectedReplaced] = useState(filters.selectedReplaced || '');
    const [selectedSingleUser, setSelectedSingleUser] = useState(filters.selectedSingleUser || '');
    const [selectedSingleUserName, setSelectedSingleUserName]=useState("");
    const [isMinimized, setIsMinimized] = useState(true);
    const [projectTypeOptions, setProjectTypeOptions] = useState([{ id: "CV", value: "CV" }, { id: "PV", value: "PV" }]);
    const [selectedProjectTypes, setSelectedProjectTypes] = useState(filters.projectType || []);
    const [projectOptions, setProjectOptions] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState(filters.project || []);
    const [IsDownloadingExcel, setIsDownloadingExcel] = useState(false);


    const handleRegionDialogOpen = () => {
        if (isPopUpOpened === false) {
            setRegionDialogOpen(true);
            setIsPopUpOpened(true);
        }
    };

    const handleResetFilters = () => {
        onResetFilters();
    };

    const fetchStatusOptions = async () => {
        try {
            const response = await authApi.get("/Tickets/GetTicketStatuses");
            setStatusOptions(response.data.ticketStauses);
        } catch (error) {
            console.error("Error fetching status options:", error);
        }
    };


    useEffect(() => {
        if (pStatusOptions === undefined) {
            fetchStatusOptions();
        } else {
            setStatusOptions(pStatusOptions);
        }
        //setTimeout(() => {
        const minimized = (filters.projectType != undefined && filters.projectType.length > 0) || ((filters.project != undefined && filters.project.length > 0) ? true : false);
        console.log("filters.projectType", user);
        setIsMinimized(!minimized);

        //}, 1000);
    }, [pStatusOptions]);


    useEffect(() => {
        if (filters.status) {
            setSelectedStatuses(filters.status);
        } else {
            setSelectedStatuses([]);
        }
    }, [filters.status]);

    useEffect(() => {
        if (filters.dtcCodes) {
            setSelectedDtcCodes(filters.dtcCodes);
        } else {
            setSelectedDtcCodes([]);
        }
    }, [filters.dtcCodes]);
    useEffect(() => {
        if (filters.causalParts) {
            setSelectedCausalParts(filters.causalParts);
        } else {
            setSelectedCausalParts([]);
        }
    }, [filters.causalParts]);
    useEffect(() => {
        if (filters.ccCategory) {
            setSelectedCcCategories(filters.ccCategory);
        } else {
            setSelectedCcCategories([]);
        }
    }, [filters.ccCategory]);

    useEffect(() => {
        if (filters.selectedUserType) {
            setSelectedUserType(filters.selectedUserType);
        } else {
            setSelectedUserType([]);
        }
    }, [filters.selectedUserType]);

    useEffect(() => {
        if (filters.selectedUser) {
            setSelectedUser(filters.selectedUser);
        } else {
            setSelectedUser([]);
        }
    }, [filters.selectedUser]);

    const handleRegionDialogClose = () => {
        setTimeout(() => {
            setIsPopUpOpened(false);
            keywordRef.current.focus();
        }, 100);
        setRegionDialogOpen(false);
    };

    const handleRegionConfirmation = (regionDetails) => {
        let regionValue = `${regionDetails.stateName}, ${regionDetails.cityName}, ${regionDetails.address}`;
        regionValue = regionValue.replace("undefined", "");
        regionDetails.region = regionValue;
        onFilterChangeBulk(regionDetails);
        handleRegionDialogClose();
    };

    const currentDate = new Date();
    const fromDate = new Date(currentDate);
    fromDate.setDate(fromDate.getDate() - 7);
    const keywordRef = useRef(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        onApplyFilters();
    };

    const handleStatusChange = (event) => {
        const value = event.target.value;
        onFilterChange("status", value);
    };

    useEffect(() => {
        if (filters.projectType) {
            setSelectedProjectTypes(filters.projectType);
        } else {
            setSelectedProjectTypes([]);
        }
    }, [filters.projectType]);

    const handleProjectTypeChange = (event) => {
        const value = event.target.value;
        onFilterChange("projectType", value);
    };

    const handleProjectChange = (event) => {
        const value = event.target.value;
        onFilterChange("project", value);
    };
    const fetchProjectOptions = async () => {
        try {
            const response = await authApi.get("/MasterData/GetProjects");
            setProjectOptions(response.data.projectsList);
        } catch (error) {
            console.error("Error fetching status options:", error);
        }
    };

    const handleDtcCodeChange = (event) => {
        const value = event.target.value;
        onFilterChange("dtcCodes", value);
    };
    const fetchDtcCodeOptions = async () => {
        try {
            const response = await authApi.get("/MasterData/GetOtherMaster?Record_For=DTC");
            setDtcCodeOptions(response.data.otherMasterList);
        } catch (error) {
            console.error("Error fetching DTC Code options:", error);
        }
    };

    const handleCausalPartChange = (event) => {
        const value = event.target.value;
        onFilterChange("causalParts", value);
    };
    const fetchCausalPartOptions = async () => {
        try {
            const response = await authApi.get("/MasterData/GetOtherMaster?Record_For=CP");
            setCausalPartOptions(response.data.otherMasterList);
        } catch (error) {
            console.error("Error fetching Causal Parts options:", error);
        }
    };

    const handleCcCategoryChange = (event) => {
        const value = event.target.value;
        onFilterChange("ccCategory", value);
    };
    const fetchCcCategoryOptions = async () => {
        try {
            const response = await authApi.get("/MasterData/GetOtherMaster?Record_For=CSTP");
            setCcCategoryOptions(response.data.otherMasterList);
        } catch (error) {
            console.error("Error fetching CcCategory options:", error);
        }
    };

    const handleUserTypeChange = (event) => {
        const value = event.target.value;
        onFilterChange("selectedUserType", value);
    };
    const fetchUserTypeOptions = async () => {
        try {
            const response = await authApi.get("/User/GetRoles");
            setUserTypeOptions(response.data.roles);
        } catch (error) {
            console.error("Error fetching roles options:", error);
        }
    };

    const handleUserChange = (event) => {
        const value = event.target.value;
        onFilterChange("selectedUser", value);
    };
    const handleRepairedChange = (event) => {
        const value = event.target.value;
        onFilterChange("selectedRepaired", value);
    };
    useEffect(() => {
        if (filters.selectedRepaired) {
            setSelectedRepaired(filters.selectedRepaired);
        } else {
            setSelectedRepaired('');
        }
    }, [filters.selectedRepaired]);

    const handleReplacedChange = (event) => {
        const value = event.target.value;
        onFilterChange("selectedReplaced", value);
    };
    useEffect(() => {
        if (filters.selectedReplaced) {
            setSelectedReplaced(filters.selectedReplaced);
        } else {
            setSelectedReplaced('');
        }
    }, [filters.selectedReplaced]);

    const handleSingleUserChange = (e) => {
        console.log(e);
        setSelectedSingleUserName(e);
        const selectedUser = UserOptions.find(
        (user) => user.userName === e
        );
        //setSelectedUserId(selectedUser.id);
        onFilterChange("selectedSingleUser", selectedUser.id);
    };
    useEffect(() => {
        
        console.log("filters.selectedSingleUser",filters.selectedSingleUser);
        if (filters.selectedSingleUser) {
            if(filters.selectedSingleUser.length==0)
                setSelectedSingleUserName("");

            setSelectedSingleUser(filters.selectedSingleUser);
        } else {
            setSelectedSingleUserName("");
            setSelectedSingleUser('');
        }
    }, [filters.selectedSingleUser]);

    const fetchUserOptions = async () => {
        try {
            const response = await authApi.get("/User/GetUsers");
            setUserOptions(response.data.users.filter((user) =>
                user.recordStatus !="P"
                ));
            console.log(response.data.users);
        } catch (error) {
            console.error("Error fetching selectedUser options:", error);
        }
    };

    useEffect(() => {
        fetchProjectOptions();
        fetchDtcCodeOptions();
        fetchCausalPartOptions();
        fetchCcCategoryOptions();
        fetchUserTypeOptions();
        fetchUserOptions();
        //setDtcCodeOptions
    }, []);

    

    useEffect(() => {
        if (filters.project) {
            setSelectedProjects(filters.project);
        } else {
            setSelectedProjects([]);
        }
    }, [filters.project]);

    

    const toggleContent = () => {
        setIsMinimized(!isMinimized);
    };
    const handleExport = async () => {
        try {
          const updatedFilters = getUpdatedFilter(filters)
          const params ={
            params: {
              PostedBy: user.role,
              PostedById: user.id,
              ...updatedFilters
            },
          }
          setIsDownloadingExcel(true);
        authApiBlob('/tickets/export',params).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'OverAllTickets.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
            setIsDownloadingExcel(false);
        });
    
          
        } catch (error) {
          console.error('Error exporting the Excel file', error);
        }
      };
      
    const YesNoValues = [
        { id: 1, value: "Yes" },
        { id: 0, value: "No" }
    ];

    return (
        <StyledCard>
            <CardContent>
                { hasUserPermission(user, "Expand Filter", "Allow") && (
                    <IconButton
                        onClick={toggleContent}
                        aria-label="minimize-maximize"
                        style={{ right: '20px', top: '60px', position: 'absolute', fontSize: '20px' }}
                    >
                        
                            {isMinimized ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </IconButton>
    )}
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={10}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={1}>
                                            <FilterLabel>Filter:</FilterLabel>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                type="date"
                                                label="From Date"
                                                InputLabelProps={{ shrink: true }}
                                                value={filters.fromDate}
                                                onChange={(e) => onFilterChange("fromDate", e.target.value)}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                type="date"
                                                label="To Date"
                                                InputLabelProps={{ shrink: true }}
                                                value={filters.toDate}
                                                onChange={(e) => onFilterChange("toDate", e.target.value)}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel size="small">Select Status</InputLabel>
                                                <Select
                                                    multiple
                                                    value={selectedStatuses}
                                                    onChange={handleStatusChange}
                                                    label="Select Status"
                                                    size="small"
                                                    renderValue={(selected) =>
                                                        statusOptions
                                                            .filter(option => selected.includes(option.id))
                                                            .map(option => option.ticketStatus)
                                                            .join(', ')
                                                    }
                                                >
                                                    <MenuItem disabled value="">
                                                        Select Status
                                                    </MenuItem>
                                                    {statusOptions.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            <Checkbox
                                                                checked={selectedStatuses.includes(option.id)}
                                                            />
                                                            <ListItemText primary={option.ticketStatus} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                type="text"
                                                label="Region"
                                                value={filters.region || ""}
                                                onFocus={handleRegionDialogOpen}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                            />
                                            <RegionPopup
                                                open={regionDialogOpen}
                                                onClose={handleRegionDialogClose}
                                                onConfirm={handleRegionConfirmation}
                                                regionObj={filters}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                type="text"
                                                label="Keyword"
                                                value={filters.description || ""}
                                                onChange={(e) =>
                                                    onFilterChange("description", e.target.value)
                                                }
                                                fullWidth
                                                variant="outlined"
                                                inputRef={keywordRef}
                                                size="small"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button type="submit" variant="contained" size="small">
                                        Search
                                    </Button>
                                    <Button variant="text" onClick={handleResetFilters} size="small">
                                        Reset
                                    </Button>
                                    {hasUserPermission(user, "Complete Ticket Details", "Allow") && (
                                        !IsDownloadingExcel? 
                                            // <Button onClick={handleExport} variant="contained" size="small" >Export </Button>
                                            <Tooltip title="Download data in Excel">
                                            <IconButton onClick={handleExport} size="small">
                                                <GetAppIcon  />
                                            </IconButton>
                                            </Tooltip>
                                            :
                                            <CircularProgress size={20} />
                                        
                                    )}
                                    
                                </Grid>

                                {!isMinimized && <>
                                    <Grid item xs={10} sx={{mb:2}}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={1}>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel size="small">Project Type</InputLabel>
                                                    <Select
                                                        multiple
                                                        value={selectedProjectTypes}
                                                        onChange={handleProjectTypeChange}
                                                        label="Project Type"
                                                        size="small"
                                                        renderValue={(selected) =>
                                                            projectTypeOptions
                                                                .filter(option => selected.includes(option.id))
                                                                .map(option => option.value)
                                                                .join(', ')
                                                        }
                                                    >
                                                        <MenuItem disabled value="">
                                                            Project Type
                                                        </MenuItem>
                                                        {projectTypeOptions.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                <Checkbox
                                                                    checked={selectedProjectTypes.includes(option.id)}
                                                                />
                                                                <ListItemText primary={option.value} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel size="small">Project</InputLabel>
                                                    <Select
                                                        multiple
                                                        value={selectedProjects}
                                                        onChange={handleProjectChange}
                                                        label="Project"
                                                        size="small"
                                                        renderValue={(selected) =>
                                                            projectOptions
                                                                .filter(option => selected.includes(option.id))
                                                                .map(option => option.projectName)
                                                                .join(', ')
                                                        }
                                                    >
                                                        <MenuItem disabled value="">
                                                            Project
                                                        </MenuItem>
                                                        {projectOptions.filter(option => selectedProjectTypes.includes(option.projectType) || (selectedProjectTypes != undefined && selectedProjectTypes.length == 0)).map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                <Checkbox
                                                                    checked={selectedProjects.includes(option.id)}
                                                                />
                                                                <ListItemText primary={option.projectName} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel size="small">DTC Code</InputLabel>
                                                    <Select
                                                        multiple
                                                        value={selectedDtcCodes}
                                                        onChange={handleDtcCodeChange}
                                                        label="DTC Code"
                                                        size="small"
                                                        renderValue={(selected) =>
                                                            DtcCodeOptions
                                                                .filter(option => selected.includes(option.id))
                                                                .map(option => option.otherName)
                                                                .join(', ')
                                                        }
                                                    >
                                                        <MenuItem disabled value="">
                                                            DTC Code
                                                        </MenuItem>
                                                        {DtcCodeOptions.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                <Checkbox
                                                                    checked={selectedDtcCodes.includes(option.id)}
                                                                />
                                                                <ListItemText primary={option.otherName} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel size="small">Causal Parts</InputLabel>
                                                    <Select
                                                        multiple
                                                        value={selectedCausalParts}
                                                        onChange={handleCausalPartChange}
                                                        label="Causal Parts"
                                                        size="small"
                                                        renderValue={(selected) =>
                                                            CausalPartOptions
                                                                .filter(option => selected.includes(option.id))
                                                                .map(option => option.otherName)
                                                                .join(', ')
                                                        }
                                                    >
                                                        <MenuItem disabled value="">
                                                            Causal Parts
                                                        </MenuItem>
                                                        {CausalPartOptions.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                <Checkbox
                                                                    checked={selectedCausalParts.includes(option.id)}
                                                                />
                                                                <ListItemText primary={option.otherName} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={10}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel size="small">Users Role</InputLabel>
                                                <Select
                                                    multiple
                                                    value={selectedUserType}
                                                    onChange={handleUserTypeChange}
                                                    label="Users Role"
                                                    size="small"
                                                    renderValue={(selected) =>
                                                        UserTypeOptions
                                                            .filter(option => selected.includes(option.roleName))
                                                            .map(option => option.roleName)
                                                            .join(', ')
                                                    }
                                                >
                                                    <MenuItem disabled value="">
                                                        Users Role
                                                    </MenuItem>
                                                    {UserTypeOptions.map((option) => (
                                                        <MenuItem key={option.id} value={option.roleName}>
                                                            <Checkbox
                                                                checked={selectedUserType.includes(option.roleName)}
                                                            />
                                                            <ListItemText primary={option.roleName} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel size="small">Users</InputLabel>
                                                <Select
                                                    multiple
                                                    value={selectedUser}
                                                    onChange={handleUserChange}
                                                    label="Users"
                                                    size="small"
                                                    renderValue={(selected) =>
                                                        UserOptions
                                                            .filter(option => selected.includes(option.id))
                                                            .map(option => option.userName)
                                                            .join(', ')
                                                    }
                                                >
                                                    <MenuItem disabled value="">
                                                        Users
                                                    </MenuItem>
                                                    {UserOptions.filter(option => selectedUserType.includes(option.role) || selectedUserType == undefined || selectedUserType == "" || (selectedUserType != undefined && selectedUserType.length == 0)).map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            <Checkbox
                                                                checked={selectedUser.includes(option.id)}
                                                            />
                                                            <ListItemText primary={option.userName} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

{/* <Grid item xs={2}>
    <Autocomplete
        multiple
        options={UserOptions.filter(option =>
            selectedUserType.includes(option.role) 
            || selectedUserType == undefined || selectedUserType == "" || (selectedUserType != undefined && selectedUserType.length == 0)
        )}
        getOptionLabel={(option) => option.userName}
        value={UserOptions.filter(option => selectedUser.includes(option.id))}
        onChange={(event, newValue) => handleUserChange(newValue.map(option => option.id))}
        renderInput={(params) => (
            <TextField
                {...params}
                label="Users"  
                size="small"
                variant="outlined"
                placeholder="Select users"
            />
        )}
        renderOption={(option, { selected }) => {
            // Debug: Log the option and selected value
            console.log("Option:", option);
            console.log("Selected:", selected);

            return (
                <MenuItem key={option.key} value={option.key} sx={{overflowY:"hidden"}}>
                                                            <Checkbox
                                                                checked={selectedUser.includes(option.key)}
                                                            />
                                                            <ListItemText sx={{overflowY:"hidden"}} primary={option.key} />
                                                        </MenuItem>
                    // <Checkbox checked={selectedUser.includes(option.id)} />
                    // <ListItemText primary={option.key} />

            );
        }}
        isOptionEqualToValue={(option, value) => option === value}
    />
</Grid> */}
                                        <Grid item xs={2}>
                                            <FormControl  fullWidth variant="outlined">
                                                <InputLabel id="Repaired" size="small">Repaired</InputLabel>
                                                <Select
                                                    name="repaired"
                                                    size="small"
                                                    value={selectedRepaired}
                                                    onChange={handleRepairedChange}
                                                    input={<OutlinedInput size="small" label="Repaired" />}
                                                    //MenuProps={MenuProps}
                                                >
                                                    {YesNoValues.map((st) => (
                                                        <MenuItem key={st.value} value={st.value}>
                                                            {st.value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="Replaced" size="small">Replaced</InputLabel>
                                                <Select
                                                    name="replaced"
                                                    size="small"
                                                    value={selectedReplaced}
                                                    onChange={handleReplacedChange}
                                                    input={<OutlinedInput size="small" label="Replaced" />}
                                                    //MenuProps={MenuProps}
                                                >
                                                    {YesNoValues.map((st) => (
                                                        <MenuItem key={st.value} value={st.value}>
                                                            {st.value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel size="small">Customer Complaint Category</InputLabel>
                                                <Select
                                                    multiple
                                                    value={selectedCcCategories}
                                                    onChange={handleCcCategoryChange}
                                                    label="Customer Complaint Category"
                                                    size="small"
                                                    renderValue={(selected) =>
                                                        CcCategoryOptions
                                                            .filter(option => selected.includes(option.id))
                                                            .map(option => option.otherName)
                                                            .join(', ')
                                                    }
                                                >
                                                    <MenuItem disabled value="">
                                                    Customer Complaint Category
                                                    </MenuItem>
                                                    {CcCategoryOptions.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            <Checkbox
                                                                checked={selectedCcCategories.includes(option.id)}
                                                            />
                                                            <ListItemText primary={option.otherName} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        
                                    </Grid>
                                    
                                        
                                </Grid>
                                <Grid item xs={2}>
                                            {/* <FormControl fullWidth variant="outlined" sx={{paddingRight:"10px"}}>
                                                <InputLabel id="User" size="small">User</InputLabel>
                                                <Select
                                                    name="user"
                                                    size="small"
                                                    value={selectedSingleUser}
                                                    onChange={handleSingleUserChange}
                                                    input={<OutlinedInput size="small" label="User" />}
                                                >
                                                    {UserOptions.map((st) => (
                                                        <MenuItem key={st.id} value={st.id}>
                                                            {st.userName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl> */}
        <FormControl fullWidth  sx={{paddingRight:"10px"}}>
          <Autocomplete
            value={selectedSingleUserName || ""}
            onChange={(event, newValue) => {
                handleSingleUserChange(newValue);  // Pass the selected value directly
            }}
            options={UserOptions.map((user) => user.userName)}
            renderInput={(params) => <TextField {...params} 
            label="Select User"
            size="small" />}
            disableClearable
            isOptionEqualToValue={(option, value) => option === value}
          />
        </FormControl>
                                    </Grid>
                                </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </form>

            </CardContent>
        </StyledCard>
    );
};

export default FilterComponent;
