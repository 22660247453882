import { Card, Grid, styled, useTheme } from '@mui/material';
import { Fragment } from 'react';
import StatCards from './shared/StatCards';
import useAuth from 'app/hooks/useAuth';
import { hasUserPermission } from 'app/contexts/Permissions';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '5px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize',
}));

const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
}));

const H4 = styled('h4')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginBottom: '16px',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
}));

const ObdAnalytics = () => {
  const { user } = useAuth();
  
  //const canViewDashboard = hasUserPermission(user, "dashboard", "view");
  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={1}>
          <Grid item  xs={12}>
            {(  <StatCards />)}
          </Grid>
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default ObdAnalytics;
