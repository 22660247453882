import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, IconButton } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';

const AddEditRoleDialog = ({ open, handleClose, handleSubmit, data, mode }) => {
  const [newItem, setNewItem] = useState('');

  if (!data && mode === 'edit') {
    return null; // or any other fallback behavior
  }

  const validationSchema = Yup.object().shape({
    roleName: Yup.string().required('Name is required'),
    roleHierarchy: Yup.string().required('Hierarchy is required'),
    // Add more validation rules as needed
  });

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {mode === 'edit' ? 'Edit Role' : 'Add Role'}
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={{
          id: mode === 'edit' ? data.id : '0', // Include id only in edit mode
          roleName: mode === 'edit' ? data.roleName : '',
          roleHierarchy: mode === 'edit' ? data.roleHierarchy : '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
          handleClose();
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    label="Name"
                    name="roleName"
                    error={errors.roleName && touched.roleName}
                    helperText={errors.roleName && touched.roleName ? errors.roleName : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    label="Hierarchy"
                    name="roleHierarchy"
                    error={errors.roleHierarchy && touched.roleHierarchy}
                    helperText={errors.roleHierarchy && touched.roleHierarchy ? errors.roleHierarchy : ''}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {mode === 'edit' ? 'Update' : 'Add'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddEditRoleDialog;
