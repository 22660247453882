import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Icon,
  Card,
  Box,
  styled,
  ThemeProvider,
} from "@mui/material";
import { createTheme, useTheme } from "@mui/material/styles";
import ColorBlock from "app/views/OtherMaster/ColorBlock";

const CardHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "16px 24px",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: theme.palette.success.main,
}));

const Title = styled("span")(({ theme }) => ({
  fontSize: "1.2rem",
  fontWeight: "bold",
  color: theme.palette.primary.contrastText,
}));

const ResponsiveTableContainer = styled(Box)(({ theme, maxHeight }) => ({
  overflowX: "auto",
  maxHeight: maxHeight, // Set the maximum height for scrolling
  "&::-webkit-scrollbar": {
    width: "8px", // Customize the width of the scrollbar
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.success.main, // Change the color of the scrollbar thumb
    borderRadius: "4px", // Add border radius to the thumb
  },
}));

const StickyTableHeader = styled(TableHead)(({ theme, headercolorname, headerfontcolor }) => ({
  backgroundColor: headercolorname ? headercolorname : theme.palette.success.light, // Use the provided header color or fallback to theme
  "& .MuiTableCell-head": {
    color: headerfontcolor ? headerfontcolor : theme.palette.success.contrastText, // Use the provided header font color or fallback to theme
    fontWeight: "bold",
    fontSize: "1rem",
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: headercolorname ? headercolorname : theme.palette.success.light, // Use the provided header color or fallback to theme
  },
}));

const StyledTableCell = styled(TableCell)(({ theme, contentsize }) => ({
  padding: theme.spacing(1),
  "&.MuiTableCell-head": {
    borderBottom: `1px solid ${theme.palette.success.main}`,
  },
  "&:last-child": {
    textAlign: "right",
    paddingRight: "15px",
  },
  fontSize: contentsize || "20px", // Set the font size, default to 25px if not provided
}));

const Small = styled("small")(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: "#fff",
  padding: "2px 8px",
  borderRadius: "4px",
  overflow: "hidden",
  background: bgcolor,
  boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
}));

const renderCellContent = (row, column, theme,calledBy,filterQueryString) => {
  let url ="";
  let status ="&status=1,2,3,4,5,6,7,8,9,10,11,12"
  if (column.field === "total" && calledBy =="ProjectsCard") {
     url = `project=${row.id}${status}`; // Adjust the URL format as needed
  }
  else if(column.field === "total" && calledBy =="CityWiseCard")
  {
    const regionValue = `&region=,${row.name} , `;
    url = `cityId=${row.id}${status}${regionValue}`;
  }
  else if(column.field === "total" && calledBy =="StateWiseCard")
  {
    const regionValue = `&stateName=${row.name}&region=${row.name}, , `;
    url = `stateCode=${row.code}${status}${regionValue}`;
  }
  if(url !=""){
    const queryString = `/tickets/viewticket?${filterQueryString}&${url}`;
    return (
      <a href={queryString} style={{ color: theme.palette.primary.main, textDecoration: "none" }}>
        {row[column.field]}
      </a>
    );
  }
  switch (column.type) {
    case "status":
      return (
        <Small
          status={row[column.field]}
          bgcolor={
            row[column.field] === "A"
              ? theme.palette.success.main
              : row[column.field] === "P"
              ? theme.palette.secondary.main
              : theme.palette.error.main
          }
        >
          {row[column.field] === "A"
            ? "Active"
            : row[column.field] === "P"
            ? "Pending"
            : "Inactive"}
        </Small>
      );
    case "colourValue":
    case "backColorValue":
    case "fontColorValue":
      return (
        <div style={{ width: "100px", height: "20px", border: "1px solid black", backgroundColor: row[column.field] }} />
      );
    default:
      return row[column.field];
  }
};

const OctillionTableDashboard = ({ title, columns, data, actions, maxHeight, headercolorname, headerfontcolor, contentsize,calledBy,filterQueryString }) => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <Card elevation={3}>
        {title && (
          <CardHeader>
            <Title>{title}</Title>
          </CardHeader>
        )}

        <ResponsiveTableContainer maxHeight={maxHeight}>
          <Table>
            <StickyTableHeader headercolorname={headercolorname} headerfontcolor={headerfontcolor}>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell key={column.field} contentsize={contentsize}>
                    {column.headerName}
                  </StyledTableCell>
                ))}
                {actions && <StyledTableCell>Actions</StyledTableCell>}
              </TableRow>
            </StickyTableHeader>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <StyledTableCell key={column.field} contentsize={contentsize}>
                      {renderCellContent(row, column, theme,calledBy,filterQueryString)}
                    </StyledTableCell>
                  ))}
                  {actions && (
                    <StyledTableCell>
                      {actions.map((action, actionIndex) => (
                        <IconButton
                          key={actionIndex}
                          onClick={() => action.onClick(row)}
                        >
                          <Icon color={action.color}>{action.icon}</Icon>
                        </IconButton>
                      ))}
                    </StyledTableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ResponsiveTableContainer>
      </Card>
    </ThemeProvider>
  );
};

export default OctillionTableDashboard;
