import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import CitySearch from "./CitySearch";
import { useEffect } from "react";
import authApi from "api/authapi";



const RegionPopup = ({ open, onClose, onConfirm,regionObj }) => {
  const [regionDetails, setRegionDetails] = useState(regionObj);
//     {
//     stateCode: "",
//     stateName: "",
//     cityId: "",
//     cityName:"",
//     address: "",
//   });
  const [states, setStates] = useState([]);
  useEffect(() => {
    setRegionDetails(regionObj);
  }, [regionObj]);

  useEffect(() => {
      fetchStates();
    }, []);
    const fetchStates = async () => {
      try {
        const response = await authApi.get("/MasterData/GetStateDetails");
        setStates(response.data.states);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const handleStateChange = (e) => {
        if(e.target.value !=""){
            const selectedStateCode = e.target.value;
            const selectedState = states.find((st) => st.stateCode === selectedStateCode);
            const selectedStateName = selectedState ? selectedState.stateName : ""; // Handle case when state is not found          
            handleRegionDetailsChange("stateCode",selectedStateCode);
            handleRegionDetailsChange("stateName",selectedStateName);
        }
        else
        {
            handleRegionDetailsChange("stateCode","");
            handleRegionDetailsChange("stateName","");
        }
      };
    const handleCityChange = (selectedCity) => {
        if(selectedCity!=""){
          console.log("Selected City:", selectedCity);
          handleRegionDetailsChange("cityId",selectedCity.id);
          handleRegionDetailsChange("cityName",selectedCity.cityName);
          console.log("Selected City:", selectedCity);
        }
        else
        {
            handleRegionDetailsChange("cityId",0);
            handleRegionDetailsChange("cityName","");
        }
      };
   
  const handleRegionDetailsChange = (field, value) => {
    console.log("value",value);
    console.log(" regionDetails", regionDetails);
    setRegionDetails((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    console.log(" regionDetails", regionDetails);
  };

  const handleRegionConfirmation = () => {
    const regionValue = `${regionDetails.stateName}, ${regionDetails.cityName}, ${regionDetails.address}`;
    if(regionDetails.stateName == undefined)
    regionDetails.stateName ="";
    if(regionDetails.cityName == undefined)
    regionDetails.cityName ="";
    
    onConfirm(regionDetails);
    
    onClose(); // Close the dialog after confirmation
  };
  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Region Details
      <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
      <Grid container spacing={2}>
      <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel size="small">Select State</InputLabel>
                  <Select
                    value={regionDetails.stateCode}
                    onChange={handleStateChange}
                    // {(e) =>
                    //     handleRegionDetailsChange("stateCode", e.target.value)
                    //     }
                    label="Select State"
                    size="small"
                  >
                    <MenuItem
                                    key={0}
                                    value={""}
                                    >
                        {"Select State"}
                    </MenuItem>
                            
                    {states.map((st) => (
                      <MenuItem key={st.stateCode} value={st.stateCode}>
                        {st.stateName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
        </Grid>
        <Grid item xs={12}>
                <CitySearch
                  onCityChange={handleCityChange}
                  textfieldSize="small"
                  stateSelected={regionDetails.stateCode}
                  citySelected={regionDetails.cityName}
                  hideError={1}
                />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '-15px' }}>
        <TextField
          label="Address"
          value={regionDetails.address}
          onChange={(e) =>
            handleRegionDetailsChange("address", e.target.value)
          }
          fullWidth
          variant="outlined"
          size="small"
        />
      </Grid>
      </Grid>
      </DialogContent>
      <DialogActions>
              <Button onClick={onClose} >
                Cancel
              </Button>
              <Button color="primary" onClick={handleRegionConfirmation}>
              Confirm
              </Button>
            </DialogActions>
    </Dialog>
    
  );
};

export default RegionPopup;
