import React, { useState } from "react";
import {
  Checkbox,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import OctillionTable from "app/components/OctillionTable";

// Dummy Data and initializations as in the previous examples...
const dummyRoles = [
  { id: 1, name: "Admin" },
  { id: 2, name: "Manager" },
  { id: 3, name: "Service Engineer" },
  // Add more roles as needed
];

const dummyModules = [
  { id: 1, name: "Tickets" },
  { id: 2, name: "Job Details" },
  { id: 3, name: "Battery Replacement" },
  // Add more modules as needed
];

const dummyActions = [
  { id: 1, name: "Add" },
  { id: 2, name: "Edit" },
  { id: 3, name: "Delete" },
  { id: 4, name: "View" },
  // Add more actions as needed
];

// Initialize permissions state
const generateInitialPermissions = () => {
  let initialPermissions = {};
  dummyRoles.forEach((role) => {
    initialPermissions[role.name] = {};
    dummyModules.forEach((module) => {
      initialPermissions[role.name][module.name] = {};
      dummyActions.forEach((action) => {
        initialPermissions[role.name][module.name][action.name] = false;
      });
    });
  });
  return initialPermissions;
};

const UserPermissionManagement = () => {
  const [permissions, setPermissions] = useState(generateInitialPermissions());
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const handleRoleFilterChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleModuleFilterChange = (event) => {
    setSelectedModule(event.target.value);
  };

 
  const handlePermissionChange = (
    roleName,
    moduleName,
    actionName,
    checked
  ) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [roleName]: {
        ...prevPermissions[roleName],
        [moduleName]: {
          ...prevPermissions[roleName][moduleName],
          [actionName]: checked,
        },
      },
    }));
  };
  // A function to handle saving the permissions
  const handleSavePermissions = () => {
    // For demonstration purposes, we're just logging to the console.
    // In a real app, you would send this to a backend server.
    console.log(permissions);
    alert("Permissions saved! Check the console for output.");
  };

  // Adapt the structure for the OctillionTable component
  const columns = [
    { field: "role", headerName: "Role" },
    { field: "module", headerName: "Module" },
    ...dummyActions.map((action) => ({
      field: action.name.toLowerCase(),
      headerName: action.name,
    })),
  ];

  const data = dummyRoles.flatMap((role) =>
    dummyModules.map((module) => {
      const permissionData = {
        id: `${role.id}-${module.id}`,
        role: role.name,
        module: module.name,
      };

      dummyActions.forEach((action) => {
        // Append a Checkbox component for each action into the data object
        const actionFieldName = action.name.toLowerCase();
        permissionData[actionFieldName] = (
          <Checkbox
            checked={permissions[role.name][module.name][action.name]}
            onChange={(e) =>
              handlePermissionChange(
                role.name,
                module.name,
                action.name,
                e.target.checked
              )
            }
            color="primary"
          />
        );
      });

      return permissionData;
    })
  );

  const filteredData = data.filter((row) => {
    return (
      (selectedRole ? row.role === selectedRole : true) &&
      (selectedModule ? row.module === selectedModule : true)
    );
  });

  const renderCellContent = (row, column) => {
    if (
      dummyActions.some((action) => action.name.toLowerCase() === column.field)
    ) {
      return (
        <Checkbox
          checked={row[column.field]}
          onChange={(e) =>
            handlePermissionChange(
              row.role,
              row.module,
              column.headerName,
              e.target.checked
            )
          }
          color="primary"
        />
      );
    }
    return row[column.field];
  };

  const title = "User Permissions Management";

  return (
    <Box sx={{m:2}}>
      <Box sx={{ display: "flex", justifyContent: "right", p: 2 }}>
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 130 }}>
          <InputLabel id="role-filter-label">Role</InputLabel>
          <Select
            labelId="role-filter-label"
            id="role-filter"
            value={selectedRole}
            onChange={handleRoleFilterChange}
            label="Role"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {dummyRoles.map((role) => (
              <MenuItem key={role.id} value={role.name}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 130 }}>
          <InputLabel id="module-filter-label">Module</InputLabel>
          <Select
            labelId="module-filter-label"
            id="module-filter"
            value={selectedModule}
            onChange={handleModuleFilterChange}
            label="Module"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {dummyModules.map((module) => (
              <MenuItem key={module.id} value={module.name}>
                {module.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="success"
          onClick={handleSavePermissions}
          sx={{ m: 1 }}
        >
          Save Permissions
        </Button>
      </Box>

      <OctillionTable
        title="User Permissions Management"
        columns={columns}
        data={filteredData} 
      />
    </Box>
  );
};

export default UserPermissionManagement;
