import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { SnackbarContent } from '@mui/material';

export default function SuccessDialog({ open, message, onClose }) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };

  return (
    // <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} >
      
    //   <SnackbarContent
    //     style={{ backroundColor: "green", color: "white" }}
    //     message={message}
    //     />
    // </Snackbar>
//     <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
//     <Alert
//       onClose={handleClose}
//       severity="success"
//       variant="filled"
//       sx={{ width: '100%' }}
//     >
//       {message}
//     </Alert>
//   </Snackbar>
<Snackbar
open={open}
autoHideDuration={3000}
onClose={handleClose}
anchorOrigin={{ vertical: "top", horizontal: "center" }}
>
<SnackbarContent
  style={{ backgroundColor: "green", color: "white" }}
  message={message}
/>
</Snackbar>
    
  );
}
