import React, { useEffect, useState } from "react";
import authApi from "api/authapi"; // Ensure this path is correct for your project structure
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import OctillionTable from "app/components/OctillionTablePermission";
import SuccessDialog from "../shared/SuccessDialog";

const RoleRightTable = ({ usertype, isNewUserAdded }) => {
  const [users, setUsers] = useState([]); // Original dataset
  const [roles, setRoles] = useState([]); 
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedBulkAction, setSelectedBulkAction] = useState("Change All");
  const [openProfileUpdateMsg, setOpenProfileUpdateMsg] = useState(false);

  const handleProfileUpdateMsgClose = () => {
    setOpenProfileUpdateMsg(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await fetchRoles();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    console.log("changed...")
    fetchRoleRights();
  }, [selectedRole]);
 
  const fetchRoles = async () => {
      const roleResponse = await authApi.get("/user/GetRoles");
      const roleData = roleResponse.data.roles
      console.log(roleData);
      setRoles(roleData);
      setSelectedRole(roleData[0])
  };
  const fetchRoleRights = async () => {
    if(selectedRole==null) return;
    
      const response = await authApi.get("/ModuleRights/GetModuleRights?SelectedRoleId="+selectedRole.id);
      const moduleData = response.data.moduleRightList.map((user) => ({
        ...user,
        moduleName: user.parentModuleId ?  ".                                  > " + user.moduleName : user.moduleName,
        permission: user.permission || "Not Allowed", // Default value for permission
      }));
      console.log(moduleData)
      setUsers(moduleData);
      const updatedFilteredUsers = moduleData.filter((user) => user.moduleHierarchy >= selectedRole.roleHierarchy) ;
      setFilteredUsers(updatedFilteredUsers);
      //setFilteredUsers(moduleData);
  };

  const handleRoleFilterChange = (event) => {
    const selectedRole = roles.find((role) =>role.id ===  event.target.value );
    console.log(selectedRole);
    setSelectedRole(selectedRole);

    const updatedFilteredUsers = users.filter((user) => user.moduleHierarchy >= selectedRole.roleHierarchy) ;
    setFilteredUsers(updatedFilteredUsers);

  };
  const handleBulkActionChange = (event) => {
    setSelectedBulkAction(event.target.value);
    if(event.target.value =="Change All")
      return;
    const updatedFilteredUsers = filteredUsers.map((user) => ({ ...user, permission: event.target.value })) ;
    setFilteredUsers(updatedFilteredUsers);

    const updatedUsers = users.map((user) => ({ ...user, permission: event.target.value })) ;
    setUsers(updatedUsers);
  };

  const handlePermissionChange = (event, row) => {
    const updatedFilteredUsers = filteredUsers.map((user) =>
      user.id === row.id ? { ...user, permission: event.target.value } : user
    );
    console.log(updatedFilteredUsers);
    setFilteredUsers(updatedFilteredUsers);

    const updatedUsers = users.map((user) =>
      user.id === row.id ? { ...user, permission: event.target.value } : user
    );
    setUsers(updatedUsers);
  };

  const handleSave = async () => {
    try {
      console.log(JSON.stringify(filteredUsers));
      // Assuming filteredUsers contains only the modified data
      await authApi.post("ModuleRights/EditModuleRights", {Permissions:filteredUsers} );
      setOpenProfileUpdateMsg(true);
      fetchRoleRights();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const bulkAction = [
    { id: 1, name: "Change All" },
    { id: 2, name: "Allowed" },
    { id: 3, name: "Not Allowed" },
  ];

  const columns = [
    { field: "moduleName", headerName: "Name" },
    { field: "action", headerName: "Action" },
    {field: "permission",headerName: "Permission",},
  ];

  return (
    selectedRole !=null &&
    <Box>
      <SuccessDialog
        open={openProfileUpdateMsg}
        message="User Role Rights Updated Successfully."
        onClose={handleProfileUpdateMsgClose}
      />
      <Box id="Header"  sx={{ display: "flex",marginTop:'-25px', justifyContent: "flex-end", alignItems: "center", p: 2 }}
      className="sticky-header">
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 130 }}>
          <InputLabel size="small" id="role-filter-label">Role</InputLabel>
          <Select
            labelId="role-filter-label"
            id="role-filter"
            value={selectedRole.id}
            onChange={handleRoleFilterChange}
            label="Role"
            size="small"
          >
            {roles.map((role) => (
              <MenuItem key={role.id} value={role.id}>
                {role.roleName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 130 }}>
          <Select
            labelId="role-filter-label"
            id="role-filter"
            value={selectedBulkAction}
            onChange={handleBulkActionChange}
            size="small"
          >
            {bulkAction.map((role) => (
              <MenuItem key={role.id} value={role.name}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" size="small" style={{ height: '35px' }} onClick={handleSave}>Save</Button>
      </Box>
      <Box > 
        <OctillionTable
          title="User Role Rights"
          columns={columns}
          data={filteredUsers}
          pHandlePermissionChange= {handlePermissionChange}
        />
        </Box>
    </Box>

  );
};

export default RoleRightTable;
