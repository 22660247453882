export const authRoles = {
  dev: ['Developer'], // Only Super Admin has access
  admin: ['Developer', 'Admin'], // Only SA & Admin has access
  manager:['Developer','Admin', 'Manager'],
  company:['Developer','Admin', 'Manager', 'Company'],
  dealer:['Developer','Admin', 'Manager','Company','Dealer', 'Service Engineer', 'Dealer Technicians', 'Backend Team'],
  guest: ['Developer', 'Admin', 'GUEST'] // Everyone has access
};

export const pathRoles = {
  '/tickets/create-ticket': ['Tickets', 'Add'],
  '/tickets/viewticket': ['Tickets', 'View'],
  '/dashboard/default': ['Overall Dashboard', 'Allow'],
  '/obddashboard': ['OBD Dashboard', 'Allow'],
  '/admin/role-right': ['User Role Rights', 'Edit'],
  '/State': ['States', 'Add_Edit_Delete'],
  '/Cities': ['Cities', 'Add_Edit_Delete'],
  '/Project': ['Projects', 'Add_Edit_Delete'],
  '/TicketStatus': ['Ticket Status', 'Add_Edit_Delete'],
  '/OtherMaster/DT': ['Problem Statements', 'Add_Edit_Delete'],
  '/OtherMaster/DTC': ['DTC Codes', 'Add_Edit_Delete'],
  '/OtherMaster/CSTP': ['Complaint Category', 'Add_Edit_Delete'],
  '/OtherMaster/RT': ['Resolution Type', 'Add_Edit_Delete'],
  '/OtherMaster/CA': ['Causal Aggregate', 'Add_Edit_Delete'],
  '/OtherMaster/CP': ['Causal Parts', 'Add_Edit_Delete'],
  '/Country': ['Country', 'Add_Edit_Delete'],
  '/OtherMaster/RECT': ['Rectification Types', 'Add_Edit_Delete'],
  '/OtherMaster/OA': ['Other Activities', 'Add_Edit_Delete'],
  '/admin/dealer-detail': ['Dealers', 'Approve / Reject'],
  '/admin/company-detail': ['Companies', 'Add_Edit_Approve / Reject'],
  '/admin/users-detail': ['Users', 'Add_Edit_Approve / Reject'],
  
};


export const hasUserPermission = (user, securityName, securityAction) => {
  return user.userModuleDetails.modules.some(
    module =>
      module.moduleName.toLowerCase() === securityName.toLowerCase() &&
      module.moduleActions.some(
        action =>
          securityAction.toLowerCase().includes(action.actionName.toLowerCase()) &&
          action.isAllowed
      )
  );
};
