import { Fragment, useState, useEffect } from "react";
import {
  Grid,
  styled,
} from "@mui/material";
import authApi from "api/authapi";
import ModuleActionTable from "./RoleTable";
//import AddUserForm from "./AddUserForm";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
}));



const Role = () => {
  

  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ModuleActionTable usertype="users"/>
          </Grid>
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default Role;
